/* Full-page container */
.product-page {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  /* Background element with similar animated style and a blur filter */
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    background-image:
      radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
      linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
    background-size: cover, 50px 50px;
    background-blend-mode: overlay;
    animation: moveBackground 25s linear infinite;
    filter: blur(3px);
    z-index: -1;
  }
  
  /* Container for the content; ensures text sits above the blurred background */
  .product-content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  /* Glowing box styling for the "Coming soon!" text */
  .coming-soon-box {
    display: inline-block;
    padding: 20px 30px;
    border: 4px solid #00FF9D;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #00FF9D;
    font-family: 'Eurostile', Arial, sans-serif;
    font-size: 2em;
    text-align: center;
    animation: glowAnimation 2s infinite;
    box-shadow: 0 0 15px rgba(0, 255, 157, 0.4);
  }
  
  /* Background movement animation */
  @keyframes moveBackground {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 200px 200px;
    }
  }
  
  /* Glowing animation for the box */
  @keyframes glowAnimation {
    0%, 100% {
      box-shadow: 0 0 15px rgba(0, 255, 157, 0.4);
    }
    50% {
      box-shadow: 0 0 30px rgba(0, 255, 157, 0.8);
    }
  }
  