/* Contact Page Styling */
.contact-page {
  background: #000000;
  background-image:
    radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
    linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
  background-size: cover, 50px 50px;
  background-blend-mode: overlay;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.8);
  animation: moveBackground 25s linear infinite;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.contact-page::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 180, 110, 0) 0%,
    rgba(0, 180, 110, 0.2) 20%,  
    rgba(0, 180, 110, 0.3) 50%,  
    rgba(0, 180, 110, 0.2) 80%,  
    rgba(0, 180, 110, 0) 100%
  );
  transform: skewX(-10deg);
  animation: greenStreaks 7s linear infinite;
  pointer-events: none;
  z-index: 0;
}

/* Contact Content Container */
.contact-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  gap: 30px;
  margin-bottom: 40px;
  z-index: 1;
}

/* Contact Info Container Styling */
.contact-info-container, 
.founders-container {
  flex: 1;
  height: auto;
  border: 4px solid #00FF9D;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.4);
  position: relative;
  background: rgba(10, 10, 10, 0.8);
  border-radius: 12px;
  padding: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 20px;
}

/* Match heights of both containers - Laptop View */
@media (min-width: 769px) {
  .founders-container,
  .contact-info-container {
    height: auto;
  }

  .contact-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-info h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .contact-info p {
    margin-top: 20px;
  }

  .email-button {
    margin-top: 0px; /* Decrease margin to bring the button slightly up */
  }
}

/* Founder Info Styling */
.founder {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.founder-image {
  width: 150px;
  height: 150px;
  border: 4px solid #00FF9D;
  border-radius: 12px;
  padding: 5px;
  transition: transform 0.3s ease-in-out;
  background-color: #ffffff60;
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.4);
  object-fit: cover;
}

.founder-image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.6), 0 0 30px rgba(0, 255, 157, 0.4);
}

.founder-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.founder-info > * {
  margin: 0;
}

.founder h2 {
  font-size: 1.5em;
  color: #ffffff;
  font-family: 'Eurostile', Arial, sans-serif;
}

.founder p {
  font-size: 1em;
  color: #dddddd;
  margin: 5px 0;
  line-height: 1.2;
}

.founder a {
  font-size: 1em;
  color: #00FF9D;
  text-decoration: none;
  transition: color 0.3s ease;
}

.founder a:hover {
  color: #ffffff;
}

/* Contact Info Styling */
.contact-info {
  text-align: center;
  color: #dddddd;
  background: rgba(10, 10, 10, 0.9);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.3);
  width: 100%;
  box-sizing: border-box;
}

.contact-info h2 {
  color: #ffffff;
  margin-bottom: 10px;
  font-family: 'Eurostile', Arial, sans-serif;
}

.contact-info p {
  margin: 5px 0;
  font-size: 1.2em;
}

/* Email Us Button Styling */
.email-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #ffffff;
  background-color: #00cc7a;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.email-button:hover {
  background-color: #00b368;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.5);
}

/* Adjust button color for mobile */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .contact-info-container,
  .founders-container {
    max-width: 90%;
    width: 100%;
    margin: 20px 0;
  }

  .founder {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .founder-info {
    align-items: center;
    text-align: center;
  }

  .contact-info {
    width: 90%;
    margin: 20px auto;
  }

  .email-button {
    width: 100%;
    background-color: #00cc7a !important;
  }

  .email-button:hover {
    background-color: #00b368 !important;
  }
}

/* Animations */
@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200px 200px;
  }
}

@keyframes greenStreaks {
  0% {
    transform: translateX(-100%) skewX(-10deg);
  }
  100% {
    transform: translateX(100%) skewX(-10deg);
  }
}

/* Accessibility: Reduce Motion for Users Who Prefer It */
@media (prefers-reduced-motion: reduce) {
  .contact-page {
    animation: none;
  }
  .contact-page::after {
    animation: none;
  }
  .founder-image {
    transition: none;
  }
}
