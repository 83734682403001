/* Introduction Section Styling */
.introduction {
  /* Dark Metallic Background Matching Header */
  background: #000000;
  background-image:
    radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
    linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
  background-size: cover, 50px 50px;
  background-blend-mode: overlay;

  padding: 40px 20px; /* Increased vertical padding for better spacing */
  text-align: center; /* Center-align text for better visual appeal */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  position: relative;
  animation: moveBackground 25s linear infinite; /* Slower background animation */
}

/* Tagline Styling */
.tagline {
  font-size: 2em;
  font-weight: bold;
  color: #ffffff; /* Changed to white for better contrast */
  margin-bottom: 20px;
  opacity: 0; /* Initially hidden for animation */
  transform: translateY(-20px); /* Initial position for slide-in effect */
  animation: fadeInSlide 1s forwards; /* Apply animation */
  z-index: 1;
}

/* Highlighted Text in Tagline */
.highlight {
  color: #00FF9D; /* Bright green to stand out against dark background */
  display: inline-block;
  animation: highlightPulse 2s infinite; /* Continuous pulsing effect */
}

/* Introduction Text Styling */
.intro-text {
  font-size: 1.1em;
  color: #dddddd; /* Changed to lighter grey for better visibility */
  max-width: 800px;
  line-height: 1.6;
  padding: 0 10px;
  text-align: justify; /* Justify text for better readability */
  z-index: 1;
}

/* Light Streaks Overlay - ::after Pseudo-Element */
.introduction::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 180, 110, 0) 0%,
    rgba(0, 180, 110, 0.2) 20%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0.3) 50%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0.2) 80%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0) 100%
  );
  transform: skewX(-10deg);
  animation: greenStreaks 10s linear infinite;
  pointer-events: none;
  z-index: 0; /* Position Behind Content */
}

/* Green Light Streaks Animation */
@keyframes greenStreaks {
  0% {
    transform: translateX(-100%) skewX(-10deg);
  }
  100% {
    transform: translateX(100%) skewX(-10deg);
  }
}

/* Animations */

/* Background Movement Animation (Matching Header) */
@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200px 200px;
  }
}

/* Fade In and Slide Up Animation */
@keyframes fadeInSlide {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Pulse Animation for Highlight */
@keyframes highlightPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
}

/* Responsive Design */

/* Tablets and Small Desktops */
@media (min-width: 600px) {
  .tagline {
    font-size: 2.5em;
  }

  .intro-text {
    font-size: 1.2em;
    max-width: 750px;
  }
}

/* Large Desktops */
@media (min-width: 992px) {
  .introduction {
    padding: 60px 40px;
  }

  .tagline {
    font-size: 3em;
  }

  .intro-text {
    font-size: 1.3em;
    max-width: 900px;
  }
}

/* Accessibility: Reduce Motion for Users Who Prefer It */
@media (prefers-reduced-motion: reduce) {
  .introduction::after {
    animation: none;
  }
  .introduction {
    animation: none;
  }
  .tagline {
    animation: none;
  }
  .highlight {
    animation: none;
  }
}
