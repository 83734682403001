/* ================================
   Header Styling
   ================================ */

   .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    /* Dark Metallic Background */
    background: #000000;
    background-image:
      radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
      linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
    background-size: cover, 50px 50px;
    background-blend-mode: overlay;
  
    /* 3D Look with Reflective Sheen */
    box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.8);
    
    /* Animated Background Movement */
    animation: moveBackground 15s linear infinite;
  
    padding: 15px 30px; /* Adjusted padding */
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: hidden;
    color: #ffffff;
  }
  
  /* Background Movement Animation */
  @keyframes moveBackground {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 200px 200px;
    }
  }
  
  /* Green Light Streaks Overlay */
  .header::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 180, 110, 0) 0%,
      rgba(0, 180, 110, 0.2) 20%,
      rgba(0, 180, 110, 0.4) 50%,
      rgba(0, 180, 110, 0.2) 80%,
      rgba(0, 180, 110, 0) 100%
    );
    transform: skewX(-10deg);
    animation: greenStreaks 10s linear infinite;
    pointer-events: none;
    z-index: 0;
  }
  
  /* Green Light Streaks Animation */
  @keyframes greenStreaks {
    0% {
      transform: translateX(-100%) skewX(-10deg);
    }
    100% {
      transform: translateX(100%) skewX(-10deg);
    }
  }
  
  /* Header Content Above Overlay */
  .header > * {
    position: relative;
    z-index: 1;
  }
  
  /* ================================
     Logo Styling
     ================================ */
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  /* Initially Hide Both Logos */
  .logo-image {
    display: none;
  }
  
  /* Mobile Logo */
  .logo-mobile {
    display: block;
    width: 100px;
    height: auto;
    background-color: transparent;
    padding: 10px;
    border-radius: 8px;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.8));
  }
  
  /* PC Logo */
  .logo-pc {
    display: none;
    width: 300px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 8px;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.9));
  }
  
  /* Show PC Logo on Larger Screens */
  @media (min-width: 768px) {
    .logo-mobile {
      display: none;
    }
    .logo-pc {
      display: block;
    }
  }
  
  /* ================================
     Navigation Bar
     ================================ */
  
  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* Ensure all links are visible */
    gap: 10px;
  }
  
  /* Navigation Links */
  .nav a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    font-family: 'Eurostile', Arial, sans-serif;
    padding: 10px 15px;
    margin: 5px;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    white-space: nowrap;
  }
  
  /* Adjust Spacing for First Link */
  .nav a:first-child {
    margin-left: 0;
  }
  
  /* Hover Effects */
  .nav a:hover {
    color: #dddddd;
    background-color: rgba(0, 255, 157, 0.1);
    border-color: #00FF9D;
  }
  
  /* Active Link */
  .nav a.active {
    color: #ffffff;
    background-color: rgba(0, 255, 157, 0.3);
    border-color: rgba(0, 255, 157, 0.3);
  }
  
  /* ================================
     Mobile View Fixes
     ================================ */
     @media (max-width: 600px) {
      .nav {
        flex-direction: row; /* Ensure links stay horizontal */
        justify-content: space-around; /* Evenly distribute links */
        align-items: center;
        gap: 5px; /* Reduce space between links */
        padding: 8px 0;
        flex-wrap: nowrap; /* Prevent wrapping */
        width: 100%;
        overflow: hidden;
      }
    
      .nav a {
        font-size: 14px; /* Reduce font size slightly */
        padding: 6px 10px; /* Reduce padding */
        margin: 0 3px; /* Reduce margins */
        white-space: nowrap; /* Prevent breaking */
      }
    
      /* Ensure header does not get distorted */
      .header {
        padding: 12px 20px; /* Reduce padding for a compact layout */
      }
    
      /* Adjust logo size to avoid excessive height */
      .logo-mobile {
        width: 80px;
      }
    }
    