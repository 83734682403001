/* Footer Section Styling */
.footer {
  /* Dark Metallic Background Matching Header */
  background: #000000;
  background-image:
    radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
    linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
  background-size: cover, 50px 50px;
  background-blend-mode: overlay;

  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1; /* Immediately visible */
  transform: translateY(0); /* No animation at start */
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.8);
  animation: moveBackground 25s linear infinite;
  position: relative;
  overflow: hidden;
}

/* Footer Container */
.footer_container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 1;
}

/* Logo Styling */
.footer_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0); /* Dark grey semi-transparent background for visibility */
  padding: 15px; /* Padding around the logo */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Drop shadow */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.footer_logo_image {
  width: 200px; /* Increased from 150px to 200px */
  height: auto; /* Maintain aspect ratio */
}

.footer_logo:hover {
  transform: scale(1.05);
  background-color: rgba(100, 100, 100, 0.8); /* Slightly darker on hover */
}

/* Social Links Styling */
.footer_social {
  display: flex;
  gap: 20px;
}

.social_icon {
  width: 50px; /* Increased from 40px to 50px */
  height: 50px; /* Increased from 40px to 50px */
  transition: transform 0.3s ease, opacity 0.3s ease;
  background-color: rgb(255, 255, 255,0); /* Transparent white background */
  padding: 10px; /* Padding to provide contrast */
  border-radius: 50%; /* Rounded for aesthetic */
}


.social_icon:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

/* Navigation Buttons Styling */
.footer_navigation {
  display: flex;
  gap: 20px;
}

.nav_button {
  background-color: #2c3e50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.nav_button:hover {
  background-color: #1a252f;
  transform: translateY(-2px);
}

/* Light Streaks Overlay - ::after Pseudo-Element */
.footer::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 180, 110, 0) 0%,
    rgba(0, 180, 110, 0.2) 20%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0.3) 50%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0.2) 80%,  /* Reduced opacity and darker green */
    rgba(0, 180, 110, 0) 100%
  );
  transform: skewX(-10deg);
  animation: greenStreaks 7s linear infinite;
  pointer-events: none;
  z-index: 0; /* Position Behind Content */
}

/* Responsive Design */

/* Tablets and Small Desktops */
@media (min-width: 600px) {
  .footer_container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer_logo {
    padding: 20px; /* Increased padding for larger screens */
  }

  .footer_logo_image {
    width: 250px; /* Further increase logo size */
  }

  .social_icon {
    width: 60px; /* Increased size */
    height: 60px; /* Increased size */
  }

  .nav_button {
    padding: 12px 25px; /* Increased padding */
    font-size: 1.1em; /* Increased font size */
  }
}

/* Large Desktops */
@media (min-width: 992px) {
  .footer {
    padding: 60px 40px;
  }

  .footer_logo_image {
    width: 300px; /* Further increased logo size */
  }

  .social_icon {
    width: 70px; /* Increased size */
    height: 70px; /* Increased size */
  }

  .nav_button {
    padding: 14px 30px; /* Further increased padding */
    font-size: 1.2em; /* Further increased font size */
  }
}

/* Accessibility: Reduce Motion for Users Who Prefer It */
@media (prefers-reduced-motion: reduce) {
  .footer {
    animation: none;
  }
  .footer::after {
    animation: none;
  }
}
