/* ================================
   Solutions Page with Animated Background & Uniform Boxes
   ================================ */

/* Updated Solutions GIF Container with Background Animations */
.solutions-gif {
  /* Base background & gradients (from mission.css) */
  background: #000;
  background-image:
    radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
    linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
  background-size: cover, 50px 50px;
  background-blend-mode: overlay;
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.8);
  animation: moveBackground 25s linear infinite;
}

/* Light Streaks Overlay */
.solutions-gif::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 180, 110, 0) 0%,
    rgba(0, 180, 110, 0.2) 20%,
    rgba(0, 180, 110, 0.3) 50%,
    rgba(0, 180, 110, 0.2) 80%,
    rgba(0, 180, 110, 0) 100%
  );
  transform: skewX(-10deg);
  animation: greenStreaks 10s linear infinite;
  pointer-events: none;
  z-index: 0;
}

/* --------------------------
   Existing Solutions Page Styles
   -------------------------- */

/* Container: ensure children stretch to equal height */
.solutions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;  /* Changed from center to stretch */
  gap: 20px;
  max-width: 1100px;
  width: 100%;
  flex-wrap: nowrap;
}

/* Step Box: force equal width and uniform height, plus flex column for vertical alignment */
.solution-step {
  flex: 1;
  min-width: 260px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: transform 0.3s ease;
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.solution-step:hover {
  transform: scale(1.03);
}

/* Green Title Above Images */
.solution-type {
  font-size: 1.2rem;
  color: #00FF9D;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Image Container */
.step-image {
  cursor: pointer;
}

/* Clickable Image */
.clickable-image {
  width: 85%;
  max-width: 250px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.clickable-image:hover {
  transform: scale(1.08);
}

/* White Label Below Images */
.solution-name {
  font-size: 1rem;
  color: white;
  margin-top: 10px;
  font-weight: normal;
}



.popup-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 400px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.95);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  z-index: 20;
  box-shadow: 0 4px 6px rgba(0, 255, 157, 0.4);
  animation: fadeIn 0.3s ease-in-out forwards;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.popup-content p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.close-popup:hover {
  color: #00FF9D;
}

.learn-more {
  padding: 10px 15px;
  background: #00FF9D;
  color: #000;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.learn-more:hover {
  background: #00cc7a;
}



@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200px 200px;
  }
}

@keyframes greenStreaks {
  0% {
    transform: translateX(-100%) skewX(-10deg);
  }
  100% {
    transform: translateX(100%) skewX(-10deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}



@media (max-width: 992px) {
  .solutions-container {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
  }

  .solution-step {
    width: 80%;
  }

  .clickable-image {
    width: 100%;
    max-width: 240px;
  }
}
@media (prefers-reduced-motion: reduce) {
  .solutions-gif {
    animation: none;
  }
  .solutions-gif::after {
    animation: none;
  }
}


@media (max-width: 992px) {
  .solutions-container {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    /* Option 1: Center children by aligning items */
    align-items: center;
  }

  .solution-step {
    width: 80%;
    /* Option 2: Center with auto margins */
    margin: 0 auto;
    /* And then shift slightly to the right (about 1cm) */
    transform: translateX(0cm);
  }

  /* Make sure the hover state combines both the translation and the scale */
  .solution-step:hover {
    transform: translateX(0.1cm) scale(1.03);
  }

  .clickable-image {
    width: 100%;
    max-width: 240px;
  }
}
