/* ================================
   Solution Section Styling
   ================================ */

.solution {
  background: #000000;
  background-image:
    radial-gradient(circle at 20% 20%, #0a0a0a, #000000 80%),
    linear-gradient(135deg, #0d0d0d 25%, #000000 25%, #000000 50%, #0d0d0d 50%, #0d0d0d 75%, #000000 75%, #000000);
  background-size: cover, 50px 50px;
  background-blend-mode: overlay;
  padding: 20px 0; /* Adjusted padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  animation: moveBackground 25s linear infinite;
}

/* Background Animation Effect */
.solution::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 180, 110, 0) 0%,
    rgba(0, 180, 110, 0.2) 20%,
    rgba(0, 180, 110, 0.3) 50%,
    rgba(0, 180, 110, 0.2) 80%,
    rgba(0, 180, 110, 0) 100%
  );
  transform: skewX(-10deg);
  animation: greenStreaks 10s linear infinite;
  pointer-events: none;
  z-index: 0;
}

/* Content Container */
.solution-content {
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Centered Glowing Box */
.glowing-box {
  display: inline-block;
  padding: 18px 24px; /* Default size for PC */
  border: 4px solid #00FF9D;
  border-radius: 14px;
  animation: glowAnimation 2s infinite;
  box-shadow: 0 0 12px rgba(0, 255, 157, 0.3);
  background-color: rgba(0, 0, 0, 0.5);
  color: #00FF9D;
  text-align: center;
  margin: 0 auto; /* Center horizontally */
  max-width: 75%;  /* Allows the box to expand on larger screens */
}

/* Title Text */
.etols {
  font-size: 2.4em; /* Increased font size */
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 8px;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeInSlide 1s forwards;
  z-index: 1;
  text-align: center;
}

.etols span {
  font-size: clamp(1.5rem, 4.5vw, 2.5rem);
  font-weight: bold;
}

/* Subtitle */
.solution-subtitle {
  font-size: 1.8em; /* Increased font size */
  font-weight: bold;
  color: #ffffff;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeInSlide 1s forwards;
  z-index: 1;
  text-align: center;
  line-height: 1.2;
}

/* Displace subtitle to the left in mobile view */
.solution-subtitle span {
  font-size: clamp(1.1rem, 4vw, 1.8rem);
  white-space: nowrap;
}

/* ================================
   📱 Mobile Adjustments (Smaller & Shifted Subtitle)
   ================================ */

@media (max-width: 600px) {
  .solution {
    padding: 5px 0; /* Reduce vertical spacing */
    min-height: 30vh; /* Reduce section height */
  }

  .glowing-box {
    max-width: 85%; /* Reduce max width */
    padding: 10px 16px; /* Reduce padding */
    margin: 0 auto;
  }

  .etols {
    font-size: 1.6em; /* Reduce title size */
  }

  .solution-subtitle {
    font-size: 1.1em; /* Reduce subtitle size */
  }

  /* Shift subtitle slightly left */
  .solution-subtitle span {
    display: inline-block;
    transform: translateX(-10px); /* Shift left by 10px (~1cm) */
  }
}

@media (max-width: 400px) {
  .glowing-box {
    max-width: 90%;
    padding: 8px 14px;
  }

  .etols {
    font-size: 1.4em;
  }

  .solution-subtitle {
    font-size: 1em;
  }

  /* Further adjust for smaller screens */
  .solution-subtitle span {
    transform: translateX(-8px); /* Slightly smaller shift on tiny screens */
  }
}

/* Background Animations */
@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200px 200px;
  }
}

@keyframes greenStreaks {
  0% {
    transform: translateX(-100%) skewX(-10deg);
  }
  100% {
    transform: translateX(100%) skewX(-10deg);
  }
}

@keyframes glowAnimation {
  0%, 100% {
    box-shadow: 0 0 10px rgba(0, 255, 157, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 157, 0.6);
  }
}

/* Fade In Slide Animation */
@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Accessibility: Reduce Motion */
@media (prefers-reduced-motion: reduce) {
  .solution,
  .solution-content,
  .solution::after {
    animation: none;
  }
}
